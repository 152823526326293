import { action, makeObservable, observable } from "mobx";

import { NavigateFunction } from "react-router-dom";
import { PieDataObject } from "../../../../organisations/organisationDashboard/models/dashboard_interfaces";
import Pages from "../../../../../../routes/InsightRoutes";
import ModalContext from "../../../../../../core/modalZ/context/ModalContext";
import UsersApi, { UsersApi as IUsersApi } from "../../../../../../services/api/v2/users/Users.api";
import { BaseModel } from "../../../../../../core/util/BaseModel";
import { PROGRESS_STATUS_OPTIONS } from "../../../../../../constants";
import I18n from "../../../../../../core/localization/I18n";
import { CommonColDefFieldNamesEnum } from "../../../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";
import { UrlOperators } from "../../../../../../core/grids/builder/directorBuilder/grids/base/addons/FilterAddon/FilterAddonContants";
import { GetOrUrlExtension } from "../ActionsPerProjectbyRagStatusSidebar/ActionsPerProjectbyRagStatusSidebar_model";

export class ActionsPerProjectByProgressStatusSidebarModel extends BaseModel {
  userProvider: IUsersApi;
  @observable isLoading = true;
  @observable.ref drillDownPieData: PieDataObject[];
  organisationId: number;
  userId: number;
  progressStatus: number;
  pieColors: string[];
  navigate: NavigateFunction;
  authUser: FP.Entities.IUser;

  constructor(
    authUser: FP.Entities.IUser,
    organisationId: number,
    userId: number,
    progressStatus: number,
    navigate: NavigateFunction
  ) {
    super();
    makeObservable(this);
    this.userProvider = UsersApi;
    this.organisationId = organisationId;
    this.userId = userId;
    this.authUser = authUser;
    this.progressStatus = progressStatus;
    this.navigate = navigate;
    this.pieColors = [
      "#fdd886",
      "#ffc036",
      "#feae95",
      "#f49d4e",
      "#dc9700",
      "#f7820d",
      "#cc660a",
      "#966700",
      "#ed7650",
      "#ac5337",
      "#8a4100",
      "#452015"
    ];
  }

  onMount = async () => {
    await this.loadPieData();
  };

  loadPieData = async () => {
    const userProjects = await this.userProvider.getProjectsWithActionsByProgressStatusAndUserId(
      this.organisationId,
      this.userId,
      this.progressStatus
    );

    const res = userProjects.payload.map(usrProj => {
      return {
        id: usrProj.id,
        label: usrProj.label,
        value: usrProj.value,
        color: ""
      };
    });

    this.setModalPieData(res);
    return true;
  };

  getPieColors = () => {
    const result = [...this.pieColors];
    const zeroIndexHasValue = this.drillDownPieData[0].value > 0;
    const zeroIndexIsNoAccessProject = this.drillDownPieData[0].label === "Projects with no access";
    if (zeroIndexHasValue && zeroIndexIsNoAccessProject) result.unshift("#E5E5E5");
    return result;
  };

  @action
  setModalPieData = (data: PieDataObject[]) => {
    this.drillDownPieData = data;
    this.isLoading = false;
  };

  onPieClick = onClickData => {
    const link = `${Pages.projects.actions.listView.generateLink(
      this.organisationId,
      onClickData.id
    )}?progressStatus=${I18n.get(PROGRESS_STATUS_OPTIONS.find(e => e.key === this.progressStatus).label)}&${
      this.progressStatus === 3
        ? GetOrUrlExtension(this.authUser.firstName, this.authUser.lastName)
        : `${CommonColDefFieldNamesEnum.Owner}=${UrlOperators.contains.symbol}${this.authUser.firstName}%20${this.authUser.lastName}`
    }`;
    this.navigate(link);
    ModalContext.hide();
  };
}
